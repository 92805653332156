
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { overviewService } from "@/services/api/overview.service";
import { TransfersUsed } from "@/models/entities/transfers-used.interface";
import { TotalOrderingSubjectByUser } from "@/models/entities/total-ordering-subject-by-user.interface";

const StatChart = () =>
  import(/* webpackChunkName: "stat" */ "@/components/charts/stat.vue");

const PieChart = () =>
  import(/* webpackChunkName: "stat" */ "@/components/charts/pie.vue");

const overlayModule = getModule(OverlayModule, store);

@Component({
  components: {
    "stat-chart": StatChart,
    "pie-chart": PieChart,
  },
})
export default class Overview extends Vue {
  private orderingSubjectBar = 0;
  private totalOrderingSubject: TotalOrderingSubjectByUser = {
    orderingSubjectsCount: 0,
    totalOrderingSubjects: 0,
  };
  private reachedLimitTransers = false;
  private reachedLimitOrderingSubject = false;
  private firstYear = 0;
  private year = 0;

  private transfersUsed: TransfersUsed = null;

  async created(): Promise<void> {
    this.year = new Date().getFullYear();
    await Promise.all([
      this.getOrderingSubject(),
      this.GetNumTransfersByMonth(),
      this.getSubscriptionYears(),
    ]);

    if (
      this.transfersUsed.beneficiariesCount ==
      this.transfersUsed.monthlyBeneficiaries
    ) {
      this.reachedLimitTransers = true;
    }

    if (
      this.totalOrderingSubject.orderingSubjectsCount >=
      this.totalOrderingSubject.totalOrderingSubjects
    ) {
      this.reachedLimitOrderingSubject = true;
    }
  }

  private async GetNumTransfersByMonth() {
    overlayModule.showOverlay();
    await overviewService.GetNumTransfersByMonth().then((x) => {
      this.transfersUsed = x.data;
    });
  }

  private async getSubscriptionYears() {
    overlayModule.showOverlay();
    const r = await overviewService.GetSubscriptionYears();
    this.firstYear = r.data;
  }

  private async getOrderingSubject() {
    overlayModule.showOverlay();
    await overviewService.GetOrderingSubjects().then((x) => {
      this.totalOrderingSubject = x.data;
    });
    this.orderingSubjectBar =
      (this.totalOrderingSubject.orderingSubjectsCount * 100) /
      this.totalOrderingSubject.totalOrderingSubjects;
  }

  private async changeDate(operation: string) {
    if (operation == "+") {
      if (new Date().getFullYear() > this.year) this.year = this.year + 1;
    } else {
      if (this.firstYear < this.year) {
        this.year--;
      }
    }
    await this.GetNumTransfersByMonth();
  }

  private goToOfferts() {
    this.$router.push({
      name: "account",
      params: {
        context: "payments",
        payments: "overview",
      },
    });
  }
}
