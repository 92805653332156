var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[(
      (_vm.totalOrderingSubject.orderingSubjectsCount &&
        _vm.totalOrderingSubject.orderingSubjectsCount !== -1) ||
      (_vm.transfersUsed && _vm.transfersUsed.monthlyBeneficiaries !== -1)
    )?[_c('v-row',{staticClass:"pt-8",attrs:{"align":"center"}},[_c('v-col',{staticClass:"z-heading-h6 text-on-background pb-4",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$tc("overview.ConsumptionDetail"))+" ")]),_c('v-col',[_c('v-card',{staticClass:"background-color",attrs:{"width":"100%","elevation":"0"}},[_c('v-row',{staticClass:"px-6",attrs:{"align":"center"}},[(
                _vm.transfersUsed && _vm.transfersUsed.monthlyBeneficiaries !== -1
              )?_c('v-col',{staticClass:"pl-6",attrs:{"cols":"auto"}},[_c('pie-chart')],1):_vm._e(),(
                _vm.transfersUsed && _vm.transfersUsed.monthlyBeneficiaries !== -1
              )?_c('v-col',{staticClass:"pl-12",attrs:{"cols":"auto"}},[_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"auto"}},[_c('div',{staticClass:"toggle-custom-red"})]),_c('v-col',{staticClass:"px-1"},[_c('span',{staticClass:"z-body-bold text-on-background"},[_vm._v(" "+_vm._s(_vm.transfersUsed.beneficiariesCount)+" ")]),_c('span',{staticClass:"z-body-regular-small text-on-background"},[_vm._v(" "+_vm._s(_vm.$tc("overview.UsedTransfers"))+" ")])])],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"auto"}},[_c('div',{staticClass:"toggle-custom-blue"})]),_c('v-col',{staticClass:"px-1"},[_c('span',{staticClass:"z-body-bold text-on-background"},[_vm._v(" "+_vm._s(_vm.transfersUsed.monthlyBeneficiaries - _vm.transfersUsed.beneficiariesCount)+" ")]),_c('span',{staticClass:"z-body-regular-small text-on-background"},[_vm._v(" "+_vm._s(_vm.$tc("overview.RemainingTransfers"))+" ")])])],1)],1)],1)],1):_vm._e(),(
                _vm.transfersUsed && _vm.transfersUsed.monthlyBeneficiaries !== -1
              )?_c('v-spacer'):_vm._e(),_c('v-col',{staticClass:"pl-12",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"align-center"},[_c('v-col',[_c('span',{staticClass:"z-heading-h2 text-on-background"},[_vm._v(_vm._s(_vm.totalOrderingSubject.orderingSubjectsCount))]),_c('span',{staticClass:"z-body-regular-small pl-4 text-on-background"},[_vm._v(" "+_vm._s(_vm.$tc( "overview.OrderingSubject", _vm.totalOrderingSubject.orderingSubjectsCount == 1 ? 1 : 2 ))+" ")])])],1),(
                    _vm.totalOrderingSubject.orderingSubjectsCount ==
                    _vm.totalOrderingSubject.totalOrderingSubjects
                  )?_c('v-row',[_c('v-col',{staticClass:"py-3 pr-0",attrs:{"cols":"auto"}},[_c('z-tag',{attrs:{"type":"warning","text":_vm.$tc('overview.ReachedLimitOrderingSubject')}})],1)],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"pt-0 pb-8 align-center"},[_c('v-col',{staticClass:"z-body-regular-small text-on-background",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$tc("overview.NeedMore"))+" ")]),_c('v-col',[_c('z-basic-button',{attrs:{"type":"neutral"},on:{"click":function($event){return _vm.goToOfferts()}}},[_vm._v(_vm._s(_vm.$tc("overview.ShowPlans")))])],1)],1)]:_vm._e(),_c('v-row',{staticClass:"align-center pb-6"},[_c('v-col',{staticClass:"text-on-background z-heading-h6 pb-4",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$tc("overview.StatatisticsOfYear"))+" ")]),_c('v-col',[_c('v-card',{staticClass:"background-color",attrs:{"width":"100%","elevation":"0"}},[_c('v-row',{staticClass:"px-6 pt-6 pb-8",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"1","align":"end"}},[(_vm.firstYear != _vm.year)?_c('z-plain-button',{attrs:{"type":"neutral","iconOnly":"","icon":"chevron-left"},on:{"click":function($event){return _vm.changeDate('-')}}}):_vm._e()],1),_c('v-col',{staticClass:"z-heading-h6 text-on-background",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.year)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[(_vm.year < new Date().getFullYear())?_c('z-plain-button',{attrs:{"type":"neutral","iconOnly":"","icon":"chevron-right"},on:{"click":function($event){return _vm.changeDate('+')}}}):_vm._e()],1),_c('stat-chart',{attrs:{"year":_vm.year}})],1)],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }