import { NumTransfersByYear } from "@/models/entities/num-transfers-by-year.interface";
import { TotalOrderingSubjectByUser } from "@/models/entities/total-ordering-subject-by-user.interface";
import { TransfersUsed } from "@/models/entities/transfers-used.interface";
import { ApiService } from "@zelando/vuelando";

const controller = "statistics";

class OverviewService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  GetOrderingSubjects() {
    return this.getAll<TotalOrderingSubjectByUser>(
      `${controller}/getorderingsubjects`
    );
  }

  GetNumTransfersByMonth() {
    return this.getAll<TransfersUsed>(`${controller}/getnumtransfersbymonth`);
  }

  GetNumTransfersByYear(year: number) {
    return this.getAll<Array<NumTransfersByYear>>(
      `${controller}/getnumtransfersbyyear?year=${year}`
    );
  }

  GetSubscriptionYears() {
    return this.getAll<number>(`${controller}/getsubscriptionyearsbyid`);
  }
}
export const overviewService = new OverviewService();
